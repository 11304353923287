import React, { Component } from "react";
import Header from "./components/header";
//import Counters from "./components/counters";
import "./App.css";

class App extends Component {
  state = {
    counters: [
      { id: 1, value: 4 },
      { id: 2, value: 0 },
      { id: 3, value: 0 },
      { id: 4, value: 0 },
    ],
    copy: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Integer quis auctor elit sed vulputate mi. Curabitur gravida arcu ac tortor. Lorem dolor sed viverra ipsum nunc aliquet bibendum enim facilisis. Magna fringilla urna porttitor rhoncus dolor. Lectus urna duis convallis convallis tellus id interdum. Nisi vitae suscipit tellus mauris a diam. Pretium aenean pharetra magna ac placerat vestibulum lectus mauris ultrices. Varius duis at consectetur lorem. Pharetra sit amet aliquam id diam maecenas ultricies mi eget. Orci eu lobortis elementum nibh tellus molestie nunc non. Sagittis eu volutpat odio facilisis mauris.</p><p>Massa eget egestas purus viverra. Commodo odio aenean sed diam. Lorem ipsum dolor sit amet consectetur adipiscing. Ultrices iaculis nunc sed augue. Bibendum at varius vel pharetra vel turpis nunc eget. Lectus proin nibh nisl condimentum id venenatis. Vitae et leo duis ut diam quam. Lacus luctus accumsan tortor posuere ac ut consequat semper viverra. Elit pellentesque habitant morbi tristique senectus et netus et. Vel eros donec ac odio tempor. Eros donec ac odio tempor orci dapibus ultrices. Integer feugiat varius morbi. Ipsum dolor sit amet consectetur adipiscing elit. Sed augue lacus viverra vitae. Congue quisque egestas diam in arcu euismod. Hac habitasse platea dictumst vestibulum rhoncus. Eu tincidunt tortor aliquam nulla facilisi cras fermentum odio eu. Vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam. nisi est sit amet facilisis magna.</p>",
    lipsumGenNavCount: 5,
    lipsumGenNavStructure: "P",
    lipsumGenNavStructures: [
      { key: "P", desc: "Paragraph", maxCount: 10, defaultCount: 5 },
      { key: "S", desc: "Sentence", maxCount: 100, defaultCount: 5 },
      { key: "W", desc: "Word", maxCount: 1000, defaultCount: 5 },
    ],
  };

  constructor() {
    super();
    //console.log("App - Constructor");
  }

  componentDidMount() {
    // Ajax Calls etc.

    setInputFilter(
      document.getElementById("lipsumGenNavCount"),
      function (value) {
        // const maxCount = this.structureMaxCount();
        return /^\d{1}$/.test(value); // Allow digits
      }
    );
    //console.log("App - Mounted");
  }

  render() {
    //console.log("App - Rendered");
    return (
      <React.Fragment>
        <Header
          totalCounters={this.state.counters.filter((c) => c.value > 0).length}
        />
        <main>
          <div className="intro">
            <h1>Lorem Ipsum</h1>
            <p>
              Lorem ipsum is commonly used as placeholder text by graphic
              designers and publishers to preview Layouts and Typeface before
              final copy is available.
            </p>
            <a href="#generate">
              <span>Get Some!</span>
            </a>
          </div>
          <a name="generate"></a>
          <div className="lipsum-gen">
            <div className="lipsum-gen-content">
              <div className="lipsum-gen-header">Lorem Ipsum Generator</div>
              <div className="lipsum-gen-header-divider"></div>
              <div className="lipsum-gen-copy">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Integer quis auctor elit sed vulputate mi. Curabitur gravida
                  arcu ac tortor. Lorem dolor sed viverra ipsum nunc aliquet
                  bibendum enim facilisis. Magna fringilla urna porttitor
                  rhoncus dolor. Lectus urna duis convallis convallis tellus id
                  interdum. Nisi vitae suscipit tellus mauris a diam. Pretium
                  aenean pharetra magna ac placerat vestibulum lectus mauris
                  ultrices. Varius duis at consectetur lorem. Pharetra sit amet
                  aliquam id diam maecenas ultricies mi eget. Orci eu lobortis
                  elementum nibh tellus molestie nunc non. Sagittis eu volutpat
                  odio facilisis mauris.
                </p>
                <p>
                  Massa eget egestas purus viverra. Commodo odio aenean sed
                  diam. Lorem ipsum dolor sit amet consectetur adipiscing.
                  Ultrices iaculis nunc sed augue. Bibendum at varius vel
                  pharetra vel turpis nunc eget. Lectus proin nibh nisl
                  condimentum id venenatis. Vitae et leo duis ut diam quam.
                  Lacus luctus accumsan tortor posuere ac ut consequat semper
                  viverra. Elit pellentesque habitant morbi tristique senectus
                  et netus et. Vel eros donec ac odio tempor. Eros donec ac odio
                  tempor orci dapibus ultrices. Integer feugiat varius morbi.
                  Ipsum dolor sit amet consectetur adipiscing elit. Sed augue
                  lacus viverra vitae. Congue quisque egestas diam in arcu
                  euismod. Hac habitasse platea dictumst vestibulum rhoncus. Eu
                  tincidunt tortor aliquam nulla facilisi cras fermentum odio
                  eu. Vel fringilla est ullamcorper eget nulla facilisi etiam
                  dignissim diam. nisi est sit amet facilisis magna.
                </p>
              </div>
            </div>
            <div className="lipsum-gen-nav">
              <form>
                <button
                  className="lipsum-gen-nav-refresh"
                  onClick={(event) => this.handleLipsumGenNavRefresh(event)}
                />
                <div className="lipsum-gen-nav-divider"></div>
                <div>
                  <input
                    type="text"
                    id="lipsumGenNavStructure"
                    className="lipsum-gen-nav-structure"
                    value={this.state.lipsumGenNavStructure}
                    onChange={(event) =>
                      this.handleLipsumGenNavStructureUpdate(event)
                    }
                    // disabled="disabled"
                  />
                  <div></div>
                </div>
                <div className="lipsum-gen-nav-divider"></div>
                <button
                  className="lipsum-gen-nav-decrement"
                  onClick={(event) => this.handleLipsumGenNavDecrement(event)}
                />
                <div className="lipsum-gen-nav-divider"></div>
                <input
                  type="text"
                  id="lipsumGenNavCount"
                  className="lipsum-gen-nav-count"
                  value={this.state.lipsumGenNavCount}
                  onChange={(event) =>
                    this.handleLipsumGenNavCountUpdate(event)
                  }
                />
                <div className="lipsum-gen-nav-divider"></div>
                <button
                  className="lipsum-gen-nav-increment"
                  onClick={(event) => this.handleLipsumGenNavIncrement(event)}
                />
                <div className="lipsum-gen-nav-divider"></div>
                <button
                  className="lipsum-gen-nav-copy"
                  onClick={(event) => this.handleLipsumGenNavCopy(event)}
                />
              </form>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }

  handleLipsumGenNavCountUpdate = (e) => {
    //e.preventDefault();
    // Get a copy of the state array
    const oldCount = this.state.lipsumGenNavCount;
    let newCount = parseInt(e.target.value);
    let maxCount = this.structureMaxCount();
    if (newCount + 1 < maxCount) {
      console.log("old count: " + oldCount);
      this.setState({ lipsumGenNavCount: newCount }, function () {
        console.log("new count: " + this.state.lipsumGenNavCount);
        this.setCopy();
      });
    }
    return;
  };

  handleLipsumGenNavStructureUpdate = (e) => {
    let defaultCount = this.state.lipsumGenNavStructures.find(
      (c) => c.key === e.target.value
    ).defaultCount;

    this.setState({ lipsumGenNavStructure: e.target.value }, function () {
      this.setState({ lipsumGenNavCount: defaultCount ?? 7 }, function () {
        this.setCopy();
      });
    });
    return;
  };

  handleLipsumGenNavRefresh = (e) => {
    e.preventDefault();
    let defaultCount = this.state.lipsumGenNavStructures.find(
      (c) => c.key === "P"
    ).defaultCount;

    this.setState({ lipsumGenNavStructure: "P" }, function () {
      this.setState({ lipsumGenNavCount: defaultCount }, function () {
        this.setCopy();
      });
    });
    return;
  };

  handleLipsumGenNavStructure = (e) => {
    e.preventDefault();
    return;
  };

  handleLipsumGenNavIncrement = (e) => {
    e.preventDefault();
    // Get a copy of the state array
    let count = this.state.lipsumGenNavCount;
    let maxCount = this.structureMaxCount();
    if (count + 1 < maxCount) {
      console.log("old state count: " + count);
      count++;
      this.setState({ lipsumGenNavCount: count }, function () {
        console.log("new state count: " + this.state.lipsumGenNavCount);
        this.setCopy();
      });
    }

    return;
  };

  handleLipsumGenNavDecrement = (e) => {
    e.preventDefault();
    // Get a copy of the state array
    let count = this.state.lipsumGenNavCount;
    let minCount = 0;
    if (count - 1 > minCount) {
      console.log("old state count: " + count);
      count--;
      this.setState({ lipsumGenNavCount: count }, function () {
        console.log("new state count: " + this.state.lipsumGenNavCount);
        this.setCopy();
      });
    }

    return;
  };

  handleLipsumGenNavCopy = (e) => {
    e.preventDefault();

    var input = document.createElement("textarea");
    input.innerHTML =
      document.getElementsByClassName("lipsum-gen-copy")[0].innerText;
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand("copy");
    document.body.removeChild(input);
    this.selectText(document.getElementsByClassName("lipsum-gen-copy")[0]);
    return result;
  };

  structureMaxCount() {
    const structure = this.state.lipsumGenNavStructure;
    const structures = this.state.lipsumGenNavStructures;
    let maxCount = structures.find((c) => c.key === structure).maxCount;
    return maxCount;
  }

  selectText(node) {
    if (document.body.createTextRange) {
      const range = document.body.createTextRange();
      range.moveToElementText(node);
      range.select();
    } else if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(node);
      selection.removeAllRanges();
      selection.addRange(range);
    } else {
      console.warn("Could not select text in node: Unsupported browser.");
    }
  }

  setCopy() {
    let node = document.getElementsByClassName("lipsum-gen-copy")[0];

    // TODO: sub for rest service
    let text = [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Integer quis auctor elit sed vulputate mi. Curabitur gravida arcu ac tortor. Lorem dolor sed viverra ipsum nunc aliquet bibendum enim facilisis. Magna fringilla urna porttitor rhoncus dolor. Lectus urna duis convallis convallis tellus id interdum. Nisi vitae suscipit tellus mauris a diam. Pretium aenean pharetra magna ac placerat vestibulum lectus mauris ultrices. Varius duis at consectetur lorem. Pharetra sit amet aliquam id diam maecenas ultricies mi eget. Orci eu lobortis elementum nibh tellus molestie nunc non. Sagittis eu volutpat odio facilisis mauris.",
      "Massa eget egestas purus viverra. Commodo odio aenean sed diam. Lorem ipsum dolor sit amet consectetur adipiscing. Ultrices iaculis nunc sed augue. Bibendum at varius vel pharetra vel turpis nunc eget. Lectus proin nibh nisl condimentum id venenatis. Vitae et leo duis ut diam quam. Lacus luctus accumsan tortor posuere ac ut consequat semper viverra. Elit pellentesque habitant morbi tristique senectus et netus et. Vel eros donec ac odio tempor. Eros donec ac odio tempor orci dapibus ultrices. Integer feugiat varius morbi. Ipsum dolor sit amet consectetur adipiscing elit. Sed augue lacus viverra vitae. Congue quisque egestas diam in arcu euismod. Hac habitasse platea dictumst vestibulum rhoncus. Eu tincidunt tortor aliquam nulla facilisi cras fermentum odio eu. Vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam. nisi est sit amet facilisis magna.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Integer quis auctor elit sed vulputate mi. Curabitur gravida arcu ac tortor. Lorem dolor sed viverra ipsum nunc aliquet bibendum enim facilisis. Magna fringilla urna porttitor rhoncus dolor. Lectus urna duis convallis convallis tellus id interdum. Nisi vitae suscipit tellus mauris a diam. Pretium aenean pharetra magna ac placerat vestibulum lectus mauris ultrices. Varius duis at consectetur lorem. Pharetra sit amet aliquam id diam maecenas ultricies mi eget. Orci eu lobortis elementum nibh tellus molestie nunc non. Sagittis eu volutpat odio facilisis mauris.",
      "Massa eget egestas purus viverra. Commodo odio aenean sed diam. Lorem ipsum dolor sit amet consectetur adipiscing. Ultrices iaculis nunc sed augue. Bibendum at varius vel pharetra vel turpis nunc eget. Lectus proin nibh nisl condimentum id venenatis. Vitae et leo duis ut diam quam. Lacus luctus accumsan tortor posuere ac ut consequat semper viverra. Elit pellentesque habitant morbi tristique senectus et netus et. Vel eros donec ac odio tempor. Eros donec ac odio tempor orci dapibus ultrices. Integer feugiat varius morbi. Ipsum dolor sit amet consectetur adipiscing elit. Sed augue lacus viverra vitae. Congue quisque egestas diam in arcu euismod. Hac habitasse platea dictumst vestibulum rhoncus. Eu tincidunt tortor aliquam nulla facilisi cras fermentum odio eu. Vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam. nisi est sit amet facilisis magna.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Integer quis auctor elit sed vulputate mi. Curabitur gravida arcu ac tortor. Lorem dolor sed viverra ipsum nunc aliquet bibendum enim facilisis. Magna fringilla urna porttitor rhoncus dolor. Lectus urna duis convallis convallis tellus id interdum. Nisi vitae suscipit tellus mauris a diam. Pretium aenean pharetra magna ac placerat vestibulum lectus mauris ultrices. Varius duis at consectetur lorem. Pharetra sit amet aliquam id diam maecenas ultricies mi eget. Orci eu lobortis elementum nibh tellus molestie nunc non. Sagittis eu volutpat odio facilisis mauris.",
      "Massa eget egestas purus viverra. Commodo odio aenean sed diam. Lorem ipsum dolor sit amet consectetur adipiscing. Ultrices iaculis nunc sed augue. Bibendum at varius vel pharetra vel turpis nunc eget. Lectus proin nibh nisl condimentum id venenatis. Vitae et leo duis ut diam quam. Lacus luctus accumsan tortor posuere ac ut consequat semper viverra. Elit pellentesque habitant morbi tristique senectus et netus et. Vel eros donec ac odio tempor. Eros donec ac odio tempor orci dapibus ultrices. Integer feugiat varius morbi. Ipsum dolor sit amet consectetur adipiscing elit. Sed augue lacus viverra vitae. Congue quisque egestas diam in arcu euismod. Hac habitasse platea dictumst vestibulum rhoncus. Eu tincidunt tortor aliquam nulla facilisi cras fermentum odio eu. Vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam. nisi est sit amet facilisis magna.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Integer quis auctor elit sed vulputate mi. Curabitur gravida arcu ac tortor. Lorem dolor sed viverra ipsum nunc aliquet bibendum enim facilisis. Magna fringilla urna porttitor rhoncus dolor. Lectus urna duis convallis convallis tellus id interdum. Nisi vitae suscipit tellus mauris a diam. Pretium aenean pharetra magna ac placerat vestibulum lectus mauris ultrices. Varius duis at consectetur lorem. Pharetra sit amet aliquam id diam maecenas ultricies mi eget. Orci eu lobortis elementum nibh tellus molestie nunc non. Sagittis eu volutpat odio facilisis mauris.",
      "Massa eget egestas purus viverra. Commodo odio aenean sed diam. Lorem ipsum dolor sit amet consectetur adipiscing. Ultrices iaculis nunc sed augue. Bibendum at varius vel pharetra vel turpis nunc eget. Lectus proin nibh nisl condimentum id venenatis. Vitae et leo duis ut diam quam. Lacus luctus accumsan tortor posuere ac ut consequat semper viverra. Elit pellentesque habitant morbi tristique senectus et netus et. Vel eros donec ac odio tempor. Eros donec ac odio tempor orci dapibus ultrices. Integer feugiat varius morbi. Ipsum dolor sit amet consectetur adipiscing elit. Sed augue lacus viverra vitae. Congue quisque egestas diam in arcu euismod. Hac habitasse platea dictumst vestibulum rhoncus. Eu tincidunt tortor aliquam nulla facilisi cras fermentum odio eu. Vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam. nisi est sit amet facilisis magna.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Integer quis auctor elit sed vulputate mi. Curabitur gravida arcu ac tortor. Lorem dolor sed viverra ipsum nunc aliquet bibendum enim facilisis. Magna fringilla urna porttitor rhoncus dolor. Lectus urna duis convallis convallis tellus id interdum. Nisi vitae suscipit tellus mauris a diam. Pretium aenean pharetra magna ac placerat vestibulum lectus mauris ultrices. Varius duis at consectetur lorem. Pharetra sit amet aliquam id diam maecenas ultricies mi eget. Orci eu lobortis elementum nibh tellus molestie nunc non. Sagittis eu volutpat odio facilisis mauris.",
      "Massa eget egestas purus viverra. Commodo odio aenean sed diam. Lorem ipsum dolor sit amet consectetur adipiscing. Ultrices iaculis nunc sed augue. Bibendum at varius vel pharetra vel turpis nunc eget. Lectus proin nibh nisl condimentum id venenatis. Vitae et leo duis ut diam quam. Lacus luctus accumsan tortor posuere ac ut consequat semper viverra. Elit pellentesque habitant morbi tristique senectus et netus et. Vel eros donec ac odio tempor. Eros donec ac odio tempor orci dapibus ultrices. Integer feugiat varius morbi. Ipsum dolor sit amet consectetur adipiscing elit. Sed augue lacus viverra vitae. Congue quisque egestas diam in arcu euismod. Hac habitasse platea dictumst vestibulum rhoncus. Eu tincidunt tortor aliquam nulla facilisi cras fermentum odio eu. Vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam. nisi est sit amet facilisis magna.",
    ];
    let paragraphs =
      "<p>" +
      text.slice(0, this.state.lipsumGenNavCount).join("</p><p>") +
      "</p>";
    let sentances =
      "<p>" +
      text[0].split(".").slice(0, this.state.lipsumGenNavCount).join(" ") +
      "</p>";
    let words =
      "<p>" +
      text[0].split(" ").slice(0, this.state.lipsumGenNavCount).join(" ") +
      "</p>";

    if (this.state.lipsumGenNavStructure === "P") {
      console.log(
        "Setting copy to " + this.state.lipsumGenNavCount + " paragraphs"
      );
      this.setState({ copy: paragraphs }, function () {
        node.innerHTML = paragraphs;
      });
    }

    if (this.state.lipsumGenNavStructure === "S") {
      console.log(
        "Setting copy to " + this.state.lipsumGenNavCount + " sentances"
      );
      this.setState({ copy: sentances }, function () {
        node.innerHTML = sentances;
      });
    }

    if (this.state.lipsumGenNavStructure === "W") {
      console.log("Setting copy to " + this.state.lipsumGenNavCount + " words");
      this.setState({ copy: words }, function () {
        node.innerHTML = words;
      });
    }
  }
}

// Restricts input for the given textbox to the given inputFilter function.
function setInputFilter(textbox, inputFilter) {
  [
    "input",
    "keydown",
    "keyup",
    "mousedown",
    "mouseup",
    "select",
    "contextmenu",
    "drop",
  ].forEach(function (event) {
    textbox.addEventListener(event, function () {
      if (inputFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      } else if (this.hasOwnProperty("oldValue")) {
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      } else {
        this.value = "";
      }
    });
  });
}
export default App;
